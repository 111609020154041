export default {
    name: 'auth',
    component: () => import( /* webpackChunkName: "auth" */ '@/modules/auth/layout/AuthLayout.vue' ),
    redirect: { name: 'auth-login' },
    children: [
        {
            path: 'login',
            name: 'auth-login',
            component: () => import( /* webpackChunkName: "auth" */ '@/modules/auth/views/Login.vue' ),
        },
    ]
};