import { createStore } from 'vuex'

export default createStore({
    state: {
        intervalImages: '5000',
        api: 'https://grupovicoru.com/api',
        url: 'https://grupovicoru.com'
    },
    getters: {
        
    },
    mutations: {

    },
    actions: {

    },
    modules: {

    }
});