export default {
    name: 'admin',
    component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/layout/AdminLayout.vue' ),
    redirect: { name: 'admin-home' },
    children: [
        {
            path: 'home',
            name: 'admin-home',
            component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/views/Home.vue' ),
        },
        {
            path: 'about',
            name: 'admin-about',
            component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/views/About.vue' ),
        },
        {
            path: 'rent',
            name: 'admin-rent',
            component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/views/Rent.vue' ),
        },
        {
            path: 'services',
            name: 'admin-services',
            component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/views/Services.vue' ),
        },
        {
            path: 'facilities',
            name: 'admin-facilities',
            component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/views/Facilities.vue' ),
        },
        {
            path: 'contact',
            name: 'admin-contact',
            component: () => import( /* webpackChunkName: "admin" */ '@/modules/admin/views/Contact.vue' ),
        },
    ]
};