import { createRouter, createWebHashHistory } from 'vue-router'

import RouterWeb from '@/modules/web/router/index';

import { authGuard } from '@/modules/auth/router/guard';
import RouterAuth from '@/modules/auth/router/index';

import { adminGuard } from '@/modules/admin/router/guard';
import RouterAdmin from '@/modules/admin/router/index';

const routes = [
    {
        path: '/',
        ...RouterWeb
    },
    {
        path: '/auth',
        beforeEnter: [ authGuard ],
        ...RouterAuth
    },
    {
        path: '/admin',
        beforeEnter: [ adminGuard ],
        ...RouterAdmin
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;