import axios from 'axios';

import store from '@/store';

const api = store.state.api;
const endPoint = `${api}/auth`;

export const checkToken = async () => {
    try {
        const urlApi = `${endPoint}/token/`;

        const formData = new FormData();

        const token = localStorage.getItem('token');

        formData.append('token', token);

        const { data } = await axios.post(urlApi, formData);

        return { error: data.error, message: data.message }
    } catch (error) {
        return { error: true, message: error }
    }
}

export const login = async (form) => {
    try {
        const urlApi = `${endPoint}/login/`;

        const formData = new FormData();

        formData.append('form', JSON.stringify(form));

        const { data } = await axios.post(urlApi, formData);

        localStorage.setItem('token', data.token);

        return { error: data.error, message: data.message, token: data.token }
    } catch (error) {
        return { error: true, message: error, token: '' }
    }
}

export const logout = async () => {
    try {
        const urlApi = `${endPoint}/logout/`;

        const formData = new FormData();

        const token = localStorage.getItem('token');

        formData.append('token', token);

        const { data } = await axios.post(urlApi, formData);

        return { error: data.error, message: data.message }
    } catch (error) {
        return { error: true, message: error }
    }
}