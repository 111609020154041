export default {
    name: 'web',
    component: () => import( /* webpackChunkName: "web" */ '@/modules/web/layout/WebLayout.vue' ),
    redirect: { name: 'web-home' },
    children: [
        {
            path: 'home',
            name: 'web-home',
            component: () => import( /* webpackChunkName: "web" */ '@/modules/web/views/Home.vue' ),
        },
        {
            path: 'about',
            name: 'web-about',
            component: () => import( /* webpackChunkName: "web" */ '@/modules/web/views/About.vue' ),
        },
        {
            path: 'rent',
            name: 'web-rent',
            component: () => import( /* webpackChunkName: "web" */ '@/modules/web/views/Rent.vue' ),
        },
        {
            path: 'services',
            name: 'web-services',
            component: () => import( /* webpackChunkName: "web" */ '@/modules/web/views/Services.vue' ),
        },
        {
            path: 'facilities',
            name: 'web-facilities',
            component: () => import( /* webpackChunkName: "web" */ '@/modules/web/views/Facilities.vue' ),
        },
        {
            path: 'contact',
            name: 'web-contact',
            component: () => import( /* webpackChunkName: "web" */ '@/modules/web/views/Contact.vue' ),
        },
    ]
};