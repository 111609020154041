import { checkToken } from '@/modules/helpers/auth/';

export const authGuard = async (to, from, next) => {
    const storage = localStorage;

    if (storage.length === 0) {
        next();
        return;
    }

    const token = storage.getItem('token');

    if (typeof(token) === null) {
        next();
        return;
    }

    const response = await checkToken();

    if (response.error) {
        localStorage.clear();
        next();
        return;
    }

    next({ name: 'admin' });
};